import React, { useEffect, useState, useRef } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Orders from './pages/Orders';
import AllOrders from './pages/AllOrders';
import TableListPage from './pages/TableListPage';
import TableDetailsPage from './pages/TableDetailsPage';
import BillPage from './pages/BillPage';
import MenuPage from './pages/MenuPage';
import ChefOrders from './pages/ChefOrders';
import OrderDetailsPage from './pages/OrderDetailsPage';
import SettingsPage from './pages/SettingsPage';
import Report from './pages/Report';

const PrivateRoute = ({ component: Component, render, allowedRoles, ...rest }) => {
  const { token, role } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token || !allowedRoles.includes(role)) {
          return <Redirect to="/" />;
        }

        if (render) {
          return render(props);
        }

        return <Component {...props} />;
      }}
    />
  );
};

function App() {
  const { role, token, employeeId } = useSelector((state) => state.auth);
  const [orders, setOrders] = useState([]);
  const previousResponseRef = useRef(null);
  const toast = useToast();
  const pollingIntervalRef = useRef(null);
  const isFirstFetchRef = useRef(true);

  const showOrderNotification = (order, readyItems) => {
    let title = '';
    let orderDetails = '';

    if (order.hotelBookingId) {
      title = `Room Service Order #${order.id}`;
      orderDetails = `Room ${order.hotelBookingId}`;
    } else if (order.isTakeaway) {
      title = `Takeaway Order #${order.id}`;
      orderDetails = 'Takeaway';
    } else {
      title = `Table Order #${order.id}`;
      orderDetails = `Table ${order.tableNumber} (${order.area === 'ac restaurant' ? 'AC' : order.area === 'main restaurant' ? 'Main' : 'Upper'})`;
    }

    const itemsList = readyItems.map(item => 
      `${item.quantity}x ${item.menuItem.name}`
    ).join('\n');

    toast({
      title: `Items Ready in ${title}`,
      description: `${orderDetails}\n\nReady Items:\n${itemsList}`,
      status: 'success',
      duration: null,
      isClosable: true,
      position: 'top-right',
      containerStyle: {
        maxWidth: '400px'
      }
    });
  };

  const fetchOrders = async () => {
    if (!token || role !== 'WAITER') return;

    try {
      console.log('Fetching orders...');
      const response = await axios.get(
        'https://api.hotelmadhuban.co.in:443/waiter/order/getActive',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Filter orders for the current waiter
      const filteredOrders = response.data.filter(order => 
        Number(order.staffId) === Number(employeeId)
      );

      console.log('Filtered Orders:', filteredOrders);

      // Skip notification check on first fetch
      if (!isFirstFetchRef.current && previousResponseRef.current) {
        console.log('Checking for status changes...');
        console.log('Previous Response:', previousResponseRef.current);
        
        filteredOrders.forEach(currentOrder => {
          console.log(`\nChecking order #${currentOrder.id}:`);
          const previousOrder = previousResponseRef.current.find(po => po.id === currentOrder.id);
          
          if (previousOrder) {
            console.log(`Found previous state for order #${currentOrder.id}`);
            // Find items that have just become ready
            const newlyReadyItems = currentOrder.orderItems.filter(currentItem => {
              const previousItem = previousOrder.orderItems.find(pi => pi.id === currentItem.id);
              const statusChanged = previousItem && 
                     (!previousItem.preparationStatus || previousItem.preparationStatus !== 'ready') && 
                     currentItem.preparationStatus === 'ready';
              
              if (statusChanged) {
                console.log(`Item status change detected:`, {
                  orderId: currentOrder.id,
                  itemId: currentItem.id,
                  itemName: currentItem.menuItem.name,
                  previousStatus: previousItem?.preparationStatus || 'null',
                  newStatus: currentItem.preparationStatus
                });
              }
              return statusChanged;
            });

            if (newlyReadyItems.length > 0) {
              console.log(`Found ${newlyReadyItems.length} newly ready items in order #${currentOrder.id}:`, 
                newlyReadyItems.map(item => item.menuItem.name)
              );
              showOrderNotification(currentOrder, newlyReadyItems);
            }
          } else {
            console.log(`No previous state found for order #${currentOrder.id}`);
          }
        });
      } else {
        console.log('Skipping status check - first fetch or no previous response');
      }

      // Update state and store the current response
      setOrders(filteredOrders);
      previousResponseRef.current = filteredOrders;
      isFirstFetchRef.current = false;
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    }
  };

  useEffect(() => {
    if (token && role === 'WAITER') {
      isFirstFetchRef.current = true; // Reset on remount
      fetchOrders(); // Initial fetch
      pollingIntervalRef.current = setInterval(fetchOrders, 10000);
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [token, role, employeeId]);

  return (
    <Switch>
      <Route exact path="/" component={Login} />
      
      <PrivateRoute 
        path="/chef-orders" 
        component={ChefOrders} 
        allowedRoles={['CHEF', 'ADMIN', 'RECEPTION']} 
      />
      
      <PrivateRoute 
        path="/table-list" 
        component={TableListPage} 
        allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} 
      />

      <PrivateRoute 
        path="/orders" 
        allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']}
        render={(props) => <Orders {...props} currentOrders={orders} />}
      />

      <PrivateRoute 
        path="/menu" 
        component={MenuPage} 
        allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} 
      />

      <PrivateRoute 
        path="/order-details" 
        component={OrderDetailsPage} 
        allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} 
      />

      <PrivateRoute 
        path="/table/:id" 
        component={TableDetailsPage} 
        allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} 
      />
      
      <PrivateRoute 
        path="/reception-bill/:id" 
        component={BillPage} 
        allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} 
      />

      <PrivateRoute 
        path="/waiter-bill/:id" 
        allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']}
        render={(props) => <BillPage {...props} isReception={false} />}
      />
      
      <PrivateRoute 
        path="/dashboard" 
        component={Dashboard} 
        allowedRoles={['ADMIN', 'RECEPTION']} 
      />

      <PrivateRoute 
        path="/all-orders" 
        component={AllOrders} 
        allowedRoles={['ADMIN', 'RECEPTION']} 
      />

      <PrivateRoute 
        path="/reception-bill" 
        allowedRoles={['ADMIN', 'RECEPTION']}
        render={(props) => <BillPage {...props} isReception={true} />}
      />

      <PrivateRoute 
        path="/report" 
        component={Report} 
        allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} 
      />

      <PrivateRoute 
        path="/settings" 
        component={SettingsPage} 
        allowedRoles={['ADMIN']} 
      />
      
      <Redirect to="/" />
    </Switch>
  );
}

export default App;