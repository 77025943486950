import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  IconButton,
  useColorMode,
  Flex,
  useBreakpointValue,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useToast,
  SimpleGrid,
  VStack,
  Tag,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import { FaSun, FaMoon, FaChair, FaVectorSquare, FaSnowflake, FaFan, FaSearch } from 'react-icons/fa';
import Sidebar from '../components/Sidebar';
import { Icon } from '@chakra-ui/react';
import RoomCard from './RoomCard';
import { setUserDetails } from '../authSlice';

const TableListPage = () => {
  const [tables, setTables] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [activeTab, setActiveTab] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTable, setSelectedTable] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTableType, setSelectedTableType] = useState('main'); // New state for selected table type
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const { colorMode, toggleColorMode } = useColorMode();
  const displayType = useBreakpointValue({ base: 'drawer', md: 'sidebar' });
  const tagStyle = useBreakpointValue({ base: { position: 'relative', right: '10px', width: '80%' }, md: {} });
  const history = useHistory();
  const toast = useToast();
  const storedEmployeeId = localStorage.getItem('employeeId');

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const response = await axios.get('https://api.hotelmadhuban.co.in:443/table/getAll', {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        // Process tables and update status if isBookingDeleted is true
        const processedTables = response.data.map(table => {
          if (table.isBookingDeleted) {
            // If booking is deleted, automatically set status to dirty
            return {
              ...table,
              status: 'dirty'
            };
          }
          return table;
        });

        setTables(processedTables);
      } catch (error) {
        console.error('Failed to fetch tables:', error);
        toast({
          title: "Error",
          description: "Failed to fetch tables. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          'https://api.hotelmadhuban.co.in/user/user-details',
          {
            headers: { 'Authorization': `Bearer ${token}` }
          }
        );
        dispatch(setUserDetails(response.data));
      } catch (error) {
        console.error('Failed to fetch user details:', error);
        if (error.response && error.response.status === 401) {
          console.error('Authentication failed. Please check the token.');
          history.push('/login');
        }
      }
    };

    fetchTables();
    fetchUserDetails();
  }, [token, history, toast, dispatch]);

  const handleTabChange = async (tab) => {
    setActiveTab(tab);
    setSelectedTableType(tab);
    
    if (tab === 'room-service') {
      try {
        const response = await axios.get('https://api.hotelmadhuban.co.in:443/hotel/getCheckedInBookings', {
          params: {
            username: 'MadhubanAdmin',
            password: 'Hotel2024admin',
          },
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setRooms(response.data);
      } catch (error) {
        console.error('Failed to fetch rooms:', error);
        if (error.response && error.response.status === 401) {
          console.error('Authentication failed. Please check the token.');
          history.push('/login');
        }
        toast({
          title: "Error",
          description: "Failed to fetch room service data. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterItems = () => {
    if (activeTab === 'room-service') {
      return rooms.filter(room =>
        room.checkinStatus === true && 
        room.roomNumber.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return tables.filter(table => {
      const isMatch = table.tableNumber.toString().includes(searchTerm);

      if (activeTab === 'all') {
        return isMatch;
      }

      return isMatch && table.area.toLowerCase() === activeTab;
    });
  };

  const handleCardClick = async (item) => {
    if (activeTab === 'room-service') {
      console.log('=== ROOM SERVICE CARD CLICK ===');
      console.log('Room Details:', {
        roomNumber: item.roomNumber,
        customerName: item.customerName,
        bookingId: item.bookingId,
        phoneNumber: item.phoneNumber
      });
      
      history.push({
        pathname: `/table/room${item.bookingId}`,
        state: {
          isRoomService: true,
          roomNumber: item.roomNumber,
          customerName: item.customerName,
          bookingId: item.bookingId,
          phoneNumber: item.phoneNumber,
        },
      });
      return;
    }

    if (item.status && item.status.toLowerCase() === 'dirty') {
      setSelectedTable(item);
      setIsModalOpen(true);
    } else {
      try {
        const orderByTableResponse = await axios.get(
          `https://api.hotelmadhuban.co.in/waiter/order/getByTable?id=${item.id}`,
          {
            headers: { 'Authorization': `Bearer ${token}` }
          }
        );

        const tableId = orderByTableResponse.data.id;
        const tableStaffId = orderByTableResponse.data.staffId;
        const reservationId = orderByTableResponse.data.reservationId;

        if (item.status.toLowerCase() === 'vacant') {
          // Handle vacant table (no additional condition required)
          let orderResponse = { data: orderByTableResponse.data };
          history.push({
            pathname: `/table/${item.id}/reservationId=${reservationId}`,
            state: {
              order: orderResponse.data,
              tableArea: item.area
            },
          });
        } else if (item.status.toLowerCase() === 'occupied' &&
          (tableStaffId == storedEmployeeId || storedEmployeeId == null || storedEmployeeId == 'null')) {
          // Handle occupied table with condition
          let orderResponse;
          if (tableId) {
            orderResponse = await axios.get(
              `https://api.hotelmadhuban.co.in/waiter/order/getById?id=${tableId}`,
              {
                headers: { 'Authorization': `Bearer ${token}` }
              }
            );
          } else {
            orderResponse = { data: orderByTableResponse.data };
          }
          history.push({
            pathname: `/table/${item.id}/reservationId=${reservationId}`,
            state: {
              order: orderResponse.data,
              tableArea: item.area
            },
          });
        } else {
          // Unauthorized access
          toast({
            title: "Access Denied",
            description: "This table is not assigned to you.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
        }
      } catch (error) {
        console.error('Failed to fetch order:', error);
        toast({
          title: "Error",
          description: "Failed to fetch order details. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    }
  };

  const handleCleanTable = async () => {
    if (selectedTable) {
      try {
        await axios.put(
          `https://api.hotelmadhuban.co.in:443/table/updateStatus?id=${selectedTable.id}&status=vacant`,
          {},
          {
            headers: { 'Authorization': `Bearer ${token}` }
          }
        );
        setTables(prevTables => prevTables.map(table =>
          table.id === selectedTable.id ? { ...table, status: 'vacant' } : table
        ));
        setIsModalOpen(false);
        setSelectedTable(null);
        toast({
          title: "Table cleaned",
          description: "The table has been marked as vacant.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      } catch (error) {
        console.error('Failed to update table status:', error);
        toast({
          title: "Error",
          description: "Failed to update table status. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    }
  };

  const getStatusColor = (status) => {
    if (!status) return 'yellow';
    switch (status.toLowerCase()) {
      case 'vacant': return 'green';
      case 'occupied': return 'red';
      case 'dirty': return 'orange';
      default: return 'gray';
    }
  };

  const renderCards = () => {
    let filteredItems = filterItems();

    return (
      <SimpleGrid columns={{ base: 3, md: 3, lg: 4 }} spacing={{ base: 2, md: 4, lg: 6 }}>
        {filteredItems.map((item) => (
          activeTab === 'room-service' ? (
            <RoomCard
              key={item.bookingId}
              number={item.roomNumber}
              status={item.status}
              customerName={item.customerName}
              phoneNumber={item.phoneNumber}
              onClick={() => handleCardClick(item)}
              colorMode={colorMode}
            />
          ) : (
            <Box
              key={item.id}
              bg={colorMode === 'dark' ? 'gray.700' : 'white'}
              borderRadius="lg"
              boxShadow="md"
              overflow="hidden"
              cursor="pointer"
              onClick={() => handleCardClick(item)}
              transition="all 0.3s"
              _hover={{
                transform: 'translateY(-5px)',
                boxShadow: 'lg',
              }}
            >
              <Box
                borderTopRadius="lg"
                bgGradient="linear(to-r, #6c5ce7, #00cec9)"
                h="4px"
              />
              <Box p={{ base: 2, md: 4 }}>
                <Flex justify="space-between" align="center" mb={2}>
                  <Flex align="center">
                    <Text fontWeight="600" fontSize={{ base: "sm", md: "lg" }} mr={2} color={colorMode === 'dark' ? 'white' : 'black'}>
                      Table #{item.tableNumber}
                    </Text>
                    <Tag style={tagStyle} size="sm" colorScheme={getStatusColor(item.status)}>
                      {item.status || 'Unknown'}
                    </Tag>
                  </Flex>
                </Flex>
                <VStack
                  align="stretch"
                  bg={colorMode === 'dark' ? 'gray.600' : 'gray.100'}
                  borderRadius="md"
                  p={3}
                >
                  <Flex justify="space-between" align="center">
                    <Flex align="center">
                      <Icon as={FaChair} mr={2} color={colorMode === 'dark' ? 'white' : 'black'} />
                      <Text fontWeight="500" fontSize={{ base: "xs", md: "sm" }} color={colorMode === 'dark' ? 'white' : 'black'}>{item.tableCapacity}</Text>
                    </Flex>
                    <Icon as={item.ac ? FaSnowflake : FaFan} color="#6c5ce7" />
                  </Flex>
                  <Flex align="center">
                    <Icon as={FaVectorSquare} mr={2} color={colorMode === 'dark' ? 'white' : 'black'} />
                    <Text fontWeight="500" fontSize={{ base: "xs", md: "sm" }} color={colorMode === 'dark' ? 'white' : 'black'}>{item.area}</Text>
                  </Flex>
                </VStack>
              </Box>
            </Box>
          )
        ))}
      </SimpleGrid>
    );
  };

  const renderModal = () => {
    const ModalComponent = displayType === 'drawer' ? Drawer : Modal;
    const ModalBodyComponent = displayType === 'drawer' ? DrawerBody : ModalBody;
    const ModalFooterComponent = displayType === 'drawer' ? DrawerFooter : ModalFooter;

    return (
      <ModalComponent
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        placement="bottom"
        size={displayType === 'drawer' ? 'full' : 'sm'}
      >
        {displayType !== 'drawer' && <ModalOverlay />}
        <ModalContent style={{ width: '85%', height: '50vh', position: 'relative', top: '30%' }}>
          <ModalHeader>Clear Table</ModalHeader>
          <ModalBodyComponent>
            <Text fontSize="lg" mt={4}>Do you want to clear the table?</Text>
          </ModalBodyComponent>
          <ModalFooterComponent>
            <Button onClick={handleCleanTable} colorScheme="green" mr={3}>Yes, Clean Table</Button>
            <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          </ModalFooterComponent>
        </ModalContent>
      </ModalComponent>
    );
  };

  const renderTabs = () => (
    <Box overflowX="auto" whiteSpace="nowrap" pb={2} mb={4}>
      <Flex>
        <Button
          onClick={() => handleTabChange('all')}
          mr={2}
          minW="auto"
          colorScheme={activeTab === 'all' ? 'purple' : 'gray'}
        >
          All
        </Button>
        <Button
          onClick={() => handleTabChange('main restaurant')}
          mr={2}
          minW="auto"
          colorScheme={activeTab === 'main restaurant' ? 'purple' : 'gray'}
        >
          Main
        </Button>
        <Button
          onClick={() => handleTabChange('upper restaurant')}
          mr={2}
          minW="auto"
          colorScheme={activeTab === 'upper restaurant' ? 'purple' : 'gray'}
        >
          Upper
        </Button>
        <Button
          onClick={() => handleTabChange('ac restaurant')}
          mr={2}
          minW="auto"
          colorScheme={activeTab === 'ac restaurant' ? 'purple' : 'gray'}
        >
          AC
        </Button>

        {storedEmployeeId === 'null' && (
          <>
            <Button
              onClick={() => history.push('/menu?type=takeaway')}
              mr={2}
              minW="auto"
              colorScheme={activeTab === 'take away' ? 'purple' : 'gray'}
            >
              Take Away
            </Button>
            <Button
              onClick={() => handleTabChange('room-service')}
              minW="auto"
              colorScheme={activeTab === 'room-service' ? 'purple' : 'gray'}
            >
              Room Service
            </Button>
          </>
        )}
      </Flex>
    </Box>
  );

  return (
    <Flex height="100vh" overflow="hidden">
      <Sidebar />
      <Box
        flex="1"
        p={4}
        bg={colorMode === 'dark' ? 'gray.800' : 'gray.50'}
        ml={displayType === 'sidebar' ? '250px' : 0}
        mb={displayType === 'drawer' ? '60px' : 0}
        overflowY="auto"
      >
        <Flex justify="space-between" align="center" mb={6}>
          <Text
            fontSize="2.5rem"
            fontWeight="700"
            bgGradient="linear(to-r, #6c5ce7, #00cec9)"
            bgClip="text"
          >
            Tables
          </Text>
          <IconButton
            icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
            onClick={toggleColorMode}
            aria-label="Toggle Color Mode"
            variant="ghost"
            color="current"
            _hover={{ bg: 'gray.200' }}
          />
        </Flex>

        {renderTabs()}

        <InputGroup mb={4}>
          <Input
            placeholder="Search tables or rooms..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <InputRightElement>
            <FaSearch />
          </InputRightElement>
        </InputGroup>

        {renderCards()}
      </Box>
      {isModalOpen && renderModal()}
    </Flex>
  );
};

export default TableListPage;