import React from 'react';
import './MenuItemCard.css';
import { useColorModeValue } from '@chakra-ui/react';

const MenuItemCard = ({ menuItems, onQuantityChange, itemsInCart, selectedTableType, getPrice }) => {
  const codeColor = useColorModeValue('gray.600', 'gray.300');
  const descriptionColor = useColorModeValue('gray.600', 'gray.400');
  const infoTextColor = useColorModeValue('gray.600', 'gray.300');

  const handleQuantityChange = (itemId, operation) => {
    onQuantityChange(itemId, operation);
  };

  const groupedMenuItems = menuItems.reduce((acc, item) => {
    const categoryName = item.categoryName;
    if (!acc[categoryName]) {
      acc[categoryName] = [];
    }
    acc[categoryName].push(item);
    return acc;
  }, {});

  const formatPrice = (price) => {
    return Number(price).toFixed(2);
  };

  return (
    <div className="menu-item-container">
      {Object.keys(groupedMenuItems).map((categoryName) => (
        <div key={categoryName} className="category-section" data-category={categoryName}>
          <h2 className="category-name">{categoryName}</h2>
          <div className="menu-item-row">
            {groupedMenuItems[categoryName].map((item) => {
              const cartItem = itemsInCart.find((cartItem) => cartItem.id === item.id);
              const quantity = cartItem ? cartItem.quantity : 0;
              
              return (
                <div key={item.id} className="menu-item-card">
                  <div className="item-image">
                    <img src="https://via.placeholder.com/100x100" alt={item.name} />
                  </div>
                  <div className="item-details">
                    <div className="item-header">
                      <h3 className="item-name">{item.name}</h3>
                      <span className="item-code" style={{ color: codeColor }}>
                        {item.code ? `#${item.code}` : ''}
                      </span>
                    </div>
                    <p className="item-description" style={{ color: descriptionColor }}>
                      {item.description}
                    </p>
                    <div className="item-info">
                      <div className="icon-info" style={{ color: infoTextColor }}>
                        <i className="fas fa-utensils"></i>
                        <span>Serves 2</span>
                      </div>
                      <div className="icon-info" style={{ color: infoTextColor }}>
                        <i className="fas fa-clock"></i>
                        <span>25 mins</span>
                      </div>
                      <div className={`icon-info ${item.subCategory.toLowerCase().includes('veg') ? 'veg' : 'non-veg'}`} style={{ color: infoTextColor }}>
                        <span className={`dot ${item.subCategory.toLowerCase().includes('veg') ? 'veg' : 'non-veg'}`}></span>
                        <span>{item.subCategory}</span>
                      </div>
                    </div>
                    <div className="item-actions">
                      <p className="item-price">₹{formatPrice(getPrice(item))}</p>
                      <div className="quantity-picker">
                        <div className="quantity-controls">
                          <button
                            className="btn-minus"
                            onClick={() => handleQuantityChange(item.id, 'decrease')}
                            disabled={!itemsInCart.some((cartItem) => cartItem.id === item.id)}
                          >
                            <i className="fas fa-minus"></i>
                          </button>
                          <span className="quantity">{quantity}</span>
                          <button className="btn-plus" onClick={() => handleQuantityChange(item.id, 'increase')}>
                            <i className="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MenuItemCard;