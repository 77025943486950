import React, { useState, useEffect, useRef } from 'react';
import MenuItemCard from './MenuItemCard';
import { useSelector } from 'react-redux';
import './MenuPage.css';
import { useLocation, useHistory } from 'react-router-dom';
import FilterCategories from './FilterCategories';
import { Box, Flex, useColorMode, useColorModeValue, IconButton, useBreakpointValue, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerCloseButton, Button, VStack, HStack, Text, Divider } from '@chakra-ui/react';
import { FaSun, FaMoon, FaShoppingCart } from 'react-icons/fa';

import Sidebar from '../components/Sidebar';

const MenuPage = ({ selectedTableType }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = useSelector(state => state.auth.token);
  const location = useLocation();
  const history = useHistory();
  const staffId = useSelector((state) => state.auth.employeeId);
  const queryParams = new URLSearchParams(location.search);
  const tableId = queryParams.get('tableId');
  const roomId = queryParams.get('roomId');
  const reservationId = queryParams.get('reservationId');
  const orderId = queryParams.get('orderId');
  const roomDetails = location.state?.roomDetails || {};
  const existingOrders = location.state?.existingOrders || [];
  const initialTotal = location.state?.existingTotal || 0;
  const tableArea = location.state?.tableArea || '';
  const [numberPartSave, setNumberPartSave] = useState('');
  const [roomNumber, setRoomNumber] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [itemsInCart, setItemsInCart] = useState([]);
  const [totalCost, setTotalCost] = useState(initialTotal);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [id, setId] = useState(null)
  const [existingOrderId, setExistingOrderId] = useState(null);

  const [tablenumber, setTableNumber] = useState('')
  const menuContainerRef = useRef(null);
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('black', 'white');
  const [isCartOpen, setIsCartOpen] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const currentURL = window.location.href;
    console.log('currentURL', currentURL);

    // Parse the query string
    const queryString = currentURL.split('?')[1];
    if (queryString) {
      // Split the query string into key-value pairs
      const pairs = queryString.split('&');

      pairs.forEach(pair => {
        const [key, value] = pair.split('=');

        if (key === 'tableId' && value.startsWith('room')) {
          const roomInfo = value.split('/')[0]; // Get everything before the first '/'
          const roomNumber = roomInfo.slice(4); // Remove 'room' prefix
          console.log('Room:', roomNumber);
          // setRoomNumber(roomNumber);
          setRoomNumber(roomNumber)
        } else if (key.includes('number')) {
          console.log('Number:', value);
          setNumberPartSave(value);
        } else if (key === 'tableNo' && value !== 'undefined') {
          console.log('Table Number:', value);
          setTableNumber(value);
        }

        // Log all key-value pairs for debugging
        console.log(`${key}:`, value);
      });
    }
    const idMatch = currentURL.match(/\/id=(\d+)/);
    if (idMatch) {
      const id = idMatch[1];
      console.log('ID (from URL path):', id);
      setId(id);  // Assuming you have a state variable for id
    }
    const existingOrderIdMatch = currentURL.match(/existingOrderId=(\d+)/);
    if (existingOrderIdMatch) {
      const existingOrderId = existingOrderIdMatch[1];
      console.log('Existing Order ID:', existingOrderId);
      setExistingOrderId(existingOrderId);  // Assuming you have a state variable for existingOrderId
    }
  }, []);

  useEffect(() => {
    const currentURL = window.location.href;
    console.log('currentURL', currentURL);

    const numberMatch = currentURL.match(/number(\d+)/);
    if (numberMatch) {
      const numberPart = numberMatch[1];
      setNumberPartSave(numberMatch[1]);
      console.log('Numeric part next to "number":', numberPart);
    }

    console.log('Reservation ID:', reservationId);
    console.log('Order ID:', orderId);
    //setExistingOrderId(orderId)
  }, [reservationId, orderId]);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    if (menuContainerRef.current) {
      const categoryElement = menuContainerRef.current.querySelector(`.category-section[data-category="${category}"]`);
      if (categoryElement) {
        // Scroll the category to the top of the viewport
        const yOffset = -100; // Adjust this value to account for any fixed headers
        const y = categoryElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  const handleProceedClick = () => {
    if (itemsInCart.length === 0 && existingOrders.length === 0) {
      setErrorMessage('Please add at least one food item to proceed.');
      return;
    }

    // Prepare cart items with correct prices based on area
    const cartItemsWithPrices = itemsInCart.map(item => {
      const menuItem = menuItems.find(mi => mi.id === item.id);
      const price = getPrice(menuItem);
      return {
        ...item,
        price,
        totalPerItem: price * item.quantity
      };
    });

    // Check if this is a room service order
    const isRoomService = queryParams.get('type') === 'room-service';
    const bookingId = queryParams.get('id');

    if (isRoomService && bookingId) {
      history.push({
        pathname: '/order-details',
        state: { 
          isRoomService: true,
          bookingId: bookingId,
          roomNumber: location.state?.roomNumber,
          phoneNumber: location.state?.phoneNumber,
          itemsInCart: cartItemsWithPrices,
          menuItems
        }
      });
      return;
    }

    // Regular table or takeaway order
    history.push({
      pathname: `/order-details/staffid${staffId}/number${numberPartSave}/roomnumber${roomNumber}/id${id}existingOrderId${existingOrderId}`,
      search: `?reservationId=${reservationId}&orderId=${orderId}`,
      state: { 
        itemsInCart: cartItemsWithPrices, 
        menuItems, 
        tableId: tableId || `room${roomId}`, 
        roomDetails, 
        selectedTableType,
        tableArea 
      },
    });
  };

  const handleQuantityChange = (itemId, operation) => {
    setItemsInCart((prevItems) => {
      const itemIndex = prevItems.findIndex((item) => item.id === itemId);
      let updatedItems;

      if (itemIndex === -1) {
        // If item doesn't exist in cart and operation is increase, add it with quantity 1
        if (operation === 'increase') {
          return [...prevItems, { id: itemId, quantity: 1 }];
        }
        return prevItems;
      }

      // Item exists in cart, update its quantity
      updatedItems = [...prevItems];
      if (operation === 'increase') {
        updatedItems[itemIndex] = {
          ...updatedItems[itemIndex],
          quantity: updatedItems[itemIndex].quantity + 1
        };
      } else {
        updatedItems[itemIndex] = {
          ...updatedItems[itemIndex],
          quantity: updatedItems[itemIndex].quantity - 1
        };
        // Remove item if quantity becomes 0
        if (updatedItems[itemIndex].quantity === 0) {
          updatedItems.splice(itemIndex, 1);
        }
      }

      // Calculate new total cost
      const newTotalCost = updatedItems.reduce((total, item) => {
        const menuItem = menuItems.find((mi) => mi.id === item.id);
        return total + (menuItem ? getPrice(menuItem) * item.quantity : 0);
      }, initialTotal);

      setTotalCost(newTotalCost);
      setErrorMessage('');
      return updatedItems;
    });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await fetch('https://api.hotelmadhuban.co.in:443/menu-item/getAll', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        const uniqueItems = Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
        setMenuItems(uniqueItems);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchMenuItems();
  }, [token]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const categories = Array.from(new Set(menuItems.map((item) => item.categoryName))).map((categoryName) => {
    let icon;
    switch (categoryName) {
      case 'Breakfast':
        icon = 'fas fa-coffee';
        break;
      case 'Snacks':
        icon = 'fas fa-cookie-bite';
        break;
      case 'Main Course':
        icon = 'fas fa-utensils';
        break;
      case 'Side Dish':
      case 'Sides':
        icon = 'fas fa-carrot';
        break;
      case 'Condiment':
        icon = 'fas fa-pepper-hot';
        break;
      case 'Starter':
      case 'Starters':
        icon = 'fas fa-cheese';
        break;
      case 'Dessert':
        icon = 'fas fa-ice-cream';
        break;
      case 'Beverages':
        icon = 'fas fa-glass-cheers';
        break;
      default:
        icon = 'fas fa-pizza-slice';
    }
    return {
      name: categoryName,
      icon: icon,
    };
  });

  const filteredMenuItems = selectedCategory === 'All'
    ? menuItems.filter((item) => 
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (item.code && item.code.toString().includes(searchQuery))
      )
    : menuItems.filter((item) => 
        item.categoryName === selectedCategory && 
        (item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (item.code && item.code.toString().includes(searchQuery)))
      );

  const cleanString = (str) => str ? str.replace(/\/.*$/, '') : '';

  // Function to determine which price to use based on table area
  const getPrice = (item) => {
    if (!tableId || tableId.startsWith('room') || !tableArea) {
      // For room service, takeaway, or when no table area is specified
      switch (selectedTableType) {
        case 'ac':
          return item.acPrice;
        case 'upper':
          return item.upperPrice;
        case 'main':
        default:
          return item.mainPrice;
      }
    }

    // For table orders, use price based on area
    switch (tableArea.toLowerCase()) {
      case 'ac restaurant':
        return item.acPrice;
      case 'upper restaurant':
        return item.upperPrice;
      case 'main restaurant':
      default:
        return item.mainPrice;
    }
  };

  const CartView = () => {
    const totalItems = itemsInCart.reduce((total, item) => total + item.quantity, 0);
    const cartTotal = itemsInCart.reduce((total, item) => {
      const menuItem = menuItems.find(mi => mi.id === item.id);
      return total + (getPrice(menuItem) * item.quantity);
    }, 0);

    const cartContent = (
      <VStack spacing={4} align="stretch" p={4}>
        {itemsInCart.length === 0 ? (
          <Text textAlign="center" color="gray.500">Your cart is empty</Text>
        ) : (
          <>
            {itemsInCart.map(cartItem => {
              const menuItem = menuItems.find(mi => mi.id === cartItem.id);
              const itemPrice = getPrice(menuItem);
              const itemTotal = itemPrice * cartItem.quantity;
              return (
                <Box key={cartItem.id} p={3} bg={colorMode === 'dark' ? 'gray.700' : 'gray.50'} borderRadius="md">
                  <HStack justify="space-between">
                    <VStack align="start" spacing={1}>
                      <Text fontWeight="500">{menuItem?.name}</Text>
                      <Text fontSize="sm" color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>
                        ₹{itemPrice.toFixed(2)} × {cartItem.quantity}
                      </Text>
                    </VStack>
                    <Text fontWeight="bold">₹{itemTotal.toFixed(2)}</Text>
                  </HStack>
                </Box>
              );
            })}
            <Divider />
            <HStack justify="space-between" pt={2}>
              <Text fontWeight="500">Total ({totalItems} items)</Text>
              <Text fontWeight="bold">₹{cartTotal.toFixed(2)}</Text>
            </HStack>
          </>
        )}
      </VStack>
    );

    if (isMobile) {
      return (
        <Drawer
          isOpen={isCartOpen}
          placement="bottom"
          onClose={() => setIsCartOpen(false)}
          size="md"
        >
          <DrawerOverlay />
          <DrawerContent borderTopRadius="20px">
            <DrawerHeader borderBottomWidth="1px">Cart Items</DrawerHeader>
            <DrawerCloseButton />
            <DrawerBody maxHeight="70vh" overflowY="auto">
              {cartContent}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      );
    }

    return (
      <Modal isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cart Items</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {cartContent}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <Flex height="100vh" overflow="hidden" flexDirection="column">
      <Sidebar />
      <Box flex="1" p={5} ml={["0", "0", "250px"]} bg={bgColor} color={textColor} overflowY="auto">
        <div className="sticky-top-bar">
          <div className="left-section">
            <div className="back-button" onClick={handleBackClick}>
              <i className="fas fa-chevron-left"></i>
            </div>
            <div className="table-number">
              <i className="fas fa-table"></i>
              {tablenumber
                ? `Table ${cleanString(tablenumber)}`
                : roomDetails.roomNumber
                  ? `Room ${cleanString(roomDetails.roomNumber)}`
                  : ''
              }
            </div>
          </div>
          <div className="right-section hide-mobile">
            <div className="table-info">
              <div className="table-status">
                <div className="dot pulse"></div>
                <span className="status-text">Active</span>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-search-container">
          <div className="filter-container sticky">
            <FilterCategories categories={categories} onCategoryClick={handleCategoryClick} activeCategory={selectedCategory} />
          </div>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search menu items"
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-input"
            />
          </div>
        </div>
        <div className="menu-container" ref={menuContainerRef}>
          <MenuItemCard 
            menuItems={filteredMenuItems} 
            onQuantityChange={handleQuantityChange} 
            itemsInCart={itemsInCart} 
            selectedTableType={selectedTableType}
            getPrice={getPrice}
          />
        </div>
        <div className="sticky-bottom">
          <div className="sticky-bottom-content">
            <div className="item-count">
              <span className="label">Items</span>
              <span className="value">{itemsInCart.reduce((total, item) => total + item.quantity, 0)}</span>
            </div>
            <Button
              leftIcon={<FaShoppingCart />}
              onClick={() => setIsCartOpen(true)}
              colorScheme="purple"
              variant="outline"
              size="sm"
              display={itemsInCart.length > 0 ? "flex" : "none"}
              mx={2}
            >
              View Cart
            </Button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button
              className={`confirm-order-btn ${itemsInCart.length === 0 && existingOrders.length === 0 ? 'disabled' : ''}`}
              onClick={handleProceedClick}
              disabled={itemsInCart.length === 0 && existingOrders.length === 0}
            >
              Proceed
            </button>
          </div>
        </div>
        <IconButton
          icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
          onClick={toggleColorMode}
          aria-label="Toggle Color Mode"
          position="fixed"
          bottom="20px"
          right="20px"
        />
      </Box>
      <CartView />
    </Flex>
  );
};

export default MenuPage;