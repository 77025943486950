import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
  Box, 
  Flex, 
  Text, 
  VStack, 
  SimpleGrid, 
  useColorMode,
  useBreakpointValue,
  Tag,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Badge,
  HStack,
  Button,
} from '@chakra-ui/react';
import { SearchIcon, EditIcon } from '@chakra-ui/icons';
import Sidebar from '../components/Sidebar';

function formatTime(timeString) {
  const [hours, minutes] = timeString.split(':');
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
}

function getStatusColor(status) {
  if (!status) return 'yellow.400';
  switch (status.toLowerCase()) {
    case 'delivered':
      return 'green.400';
    case 'pending':
      return 'yellow.400';
    default:
      return 'gray.500';
  }
}

function Orders({ currentOrders }) {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const { colorMode } = useColorMode();
  const displayType = useBreakpointValue({ base: 'bottom', md: 'sidebar' });

  const handleOrderClick = (orderId, tableId) => {
    console.log('order id', orderId)
    history.push(`/table/${tableId}/order=${orderId}`);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEditOrder = (e, item, order) => {
    e.stopPropagation();
    console.log('Edit order item:', item, 'in order:', order);
  };

  const filteredOrders = currentOrders.filter(order => {
    const searchValue = searchTerm.toLowerCase();
    if (order.tableNumber) {
      return order.tableNumber.toString().toLowerCase().includes(searchValue);
    } else if (order.hotelBookingId) {
      return order.hotelBookingId.toString().toLowerCase().includes(searchValue);
    }
    return true;
  });

  return (
    <Flex height="100vh" overflow="hidden">
      <Sidebar />
      <Box 
        flex="1" 
        p={4} 
        bg={colorMode === 'dark' ? 'gray.800' : 'gray.50'}
        ml={displayType === 'sidebar' ? '250px' : 0}
        mb={displayType === 'bottom' ? '60px' : 0}
        overflowY="auto"
      >
        <Text
          fontSize="2.5rem"
          fontWeight="700"
          bgGradient="linear(to-r, #6c5ce7, #00cec9)"
          bgClip="text"
          mb={6}
        >
          Current Orders
        </Text>
        <InputGroup mb={6}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input 
            type="text" 
            placeholder="Search by table number" 
            value={searchTerm}
            onChange={handleSearch}
          />
        </InputGroup>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6} pb={displayType === 'bottom' ? 16 : 0}>
          {filteredOrders.map((order) => (
            <Box
              key={order.id}
              bg={colorMode === 'dark' ? 'gray.700' : 'white'}
              borderRadius="lg"
              boxShadow="md"
              overflow="hidden"
              cursor="pointer"
              onClick={() => handleOrderClick(order.id, order.tableId)}
              transition="all 0.3s"
              _hover={{
                transform: 'translateY(-5px)',
                boxShadow: 'lg',
              }}
            >
              <Box 
                borderTopRadius="lg" 
                bgGradient="linear(to-r, #6c5ce7, #00cec9)" 
                h="4px"
              />
              <Box p={4}>
                <Flex justify="space-between" align="center" mb={2}>
                  <Text fontWeight="bold">Order #{order.id}</Text>
                  <Text fontSize="sm" color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>{formatTime(order.orderTime)}</Text>
                </Flex>
                <HStack mb={4}>
                  {order.hotelBookingId ? (
                    <Badge bg={colorMode === 'dark' ? 'gray.700' : 'gray.200'}>
                      Room {order.hotelBookingId}
                    </Badge>
                  ) : (
                    !order.isTakeaway && (
                      <Badge bg={colorMode === 'dark' ? 'gray.700' : 'gray.200'}>
                        Table {order.tableNumber}
                      </Badge>
                    )
                  )}
                  <Badge colorScheme="green">
                    {order.area === 'ac restaurant' ? 'AC' : order.area === 'main restaurant' ? 'Main' : 'Upper'}
                  </Badge>
                  <Badge colorScheme={order.paymentStatus === 'Paid' ? 'pink' : 'orange'}>
                    {order.orderItems[0]?.preparationStatus || 'Pending'}
                  </Badge>
                  <Badge colorScheme="purple">
                    {order.isTakeaway ? 'Takeaway' : order.hotelBookingId ? 'Hotel' : 'Restaurant'}
                  </Badge>
                </HStack>
                <VStack align="stretch" spacing={2}>
                  {order.orderItems.map((item, itemIndex) => (
                    <Flex
                      key={itemIndex}
                      justify="space-between"
                      align="center"
                      bg={colorMode === 'dark' ? 'gray.600' : 'gray.100'}
                      p={2}
                      borderRadius="md"
                    >
                      <HStack>
                        <Text>{item.menuItem.subCategory !== 'Veg' ? '🥬' : '🍗'}</Text>
                        <VStack align="start" spacing={0}>
                          <Text>{item.menuItem.name}</Text>
                          <Text fontSize="sm" color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>x {item.quantity}</Text>
                        </VStack>
                      </HStack>
                      <VStack align="end" spacing={0}>
                        <Text fontWeight="bold">₹{item.totalPerItem.toFixed(2)}</Text>
                        <Badge colorScheme={getStatusColor(item.preparationStatus || 'Pending')}>
                          {item.preparationStatus || 'Pending'}
                        </Badge>
                        <Button
                          size="sm"
                          leftIcon={<EditIcon />}
                          onClick={(e) => handleEditOrder(e, item, order)}
                          mt={1}
                          isDisabled={order.paymentStatus === 'Paid'}
                        >
                          Edit
                        </Button>
                      </VStack>
                    </Flex>
                  ))}
                </VStack>
                <Text fontWeight="bold" textAlign="right" mt={4}>
                  Grand Total: ₹{order.totalOrderAmount.toFixed(2)}
                </Text>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Flex>
  );
}

export default Orders;