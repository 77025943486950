import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  VStack,
  SimpleGrid,
  useColorMode,
  Select,
  Input,
  Badge,
  HStack,
  IconButton,
  useBreakpointValue,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaCalendarAlt, FaTags, FaBed, FaEdit, FaFilter, FaPlus, FaTrash, FaMinus } from 'react-icons/fa';
import Sidebar from '../components/Sidebar';
import BillingModal from './BillingModel';

const MotionBox = motion(Box);
const MotionModalContent = motion(ModalContent);

const AllOrders = () => {
  const today = new Date().toISOString().split('T')[0];
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [status, setStatus] = useState('All Status');
  const [roomType, setRoomType] = useState('All');
  const [selectedOrderItem, setSelectedOrderItem] = useState(null);
  const [isEditOrderSheetVisible, setIsEditOrderSheetVisible] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const history = useHistory();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const displayType = useBreakpointValue({ base: 'drawer', md: 'inline' });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchOrders();
  }, [token, startDate, endDate]);

  useEffect(() => {
    applyFilters(status, roomType);
  }, [orders, status, roomType]);

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://api.hotelmadhuban.co.in:443/waiter/order/getDatewise?start=${startDate}&end=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const ordersWithItems = response.data.filter(order => order.orderItems && order.orderItems.length > 0);
      
      const processedOrders = ordersWithItems.map((order) => ({
        ...order,
        orderItems: groupOrderItems(order.orderItems),
        formattedTime: formatTime(order.orderTime),
        paymentStatus: order.paidAmount >= order.totalOrderAmount ? 'Paid' : 'Pending',
      }));
      setOrders(processedOrders);
      setFilteredOrders(processedOrders);
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOrderClick = (order) => {
    history.push({
      pathname: `/reception-bill/${order.id}`,
      state: {
        orders: [order],
        token: token
      }
    });
  };

  const handleEditOrder = (event, orderItem, order) => {
    event.stopPropagation();
    if (order.paymentStatus === 'Paid') {
      // You might want to show a message to the user that paid orders can't be edited
      return;
    }
    setSelectedOrderItem({ ...orderItem, orderId: order.id });
    setIsEditOrderSheetVisible(true);
  };

  const handleOrderUpdate = async (updatedItem) => {
    console.log('edited item', updatedItem);
    try {
      const response = await axios.put(
        `https://api.hotelmadhuban.co.in/order-item/edit?id=${updatedItem.id}`,
        {
          menuItemId: updatedItem.menuItem.id,
          quantity: updatedItem.quantity,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data === 'Edited Successfully!') {
        // Close the edit modal first
        setIsEditOrderSheetVisible(false);
        
        // Fetch fresh data to update the UI
        await fetchOrders();
        
        // Show success message if needed
        // You could add a toast notification here
      } else {
        throw new Error('Failed to update the order');
      }
    } catch (error) {
      console.error('Failed to update order:', error);
      // You could show an error message to the user here
    }
  };

  const handleDeleteOrderItem = async (orderItem) => {
    console.log('deleted item', orderItem);
    try {
      const response = await axios.delete(
        `https://api.hotelmadhuban.co.in/order-item/delete?orderId=${orderItem.orderId}&orderItemId=${orderItem.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data === 'Object Deleted!') {
        // Close the edit modal first
        setIsEditOrderSheetVisible(false);
        
        // Fetch fresh data to update the UI
        await fetchOrders();
        
        // Show success message if needed
        // You could add a toast notification here
      } else {
        throw new Error('Failed to delete the order item');
      }
    } catch (error) {
      console.error('Failed to delete order item:', error);
      // You could show an error message to the user here
    }
  };

  const groupOrderItems = (orderItems) => {
    const groupedItems = {};
    orderItems.forEach((item) => {
      const itemId = item.menuItem.id;
      if (groupedItems[itemId]) {
        groupedItems[itemId].quantity += item.quantity;
      } else {
        groupedItems[itemId] = { ...item };
      }
    });
    return Object.values(groupedItems);
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleRoomTypeChange = (event) => {
    setRoomType(event.target.value);
    applyFilters(status, event.target.value);
  };

  const applyFilters = (selectedStatus, selectedType) => {
    const filtered = orders.filter((order) => {
      const statusMatch = selectedStatus === 'All Status' || order.orderItems.some((item) => item.preparationStatus && item.preparationStatus === selectedStatus);
      let typeMatch = true;

      if (selectedType !== 'All') {
        if (selectedType === 'Hotel') {
          typeMatch = order.hotelBookingId !== null;
        } else if (selectedType === 'Takeaway') {
          typeMatch = order.isTakeaway;
        } else {
          typeMatch = order.area === selectedType;
        }
      }

      return statusMatch && typeMatch;
    });
    setFilteredOrders(filtered);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    applyFilters(e.target.value, roomType);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'PENDING': return 'yellow.400';
      case 'PREPARING': return 'blue.400';
      case 'READY': return 'green.400';
      case 'DELIVERED': return 'purple.400';
      default: return 'gray.400';
    }
  };

  const getOrderType = (order) => {
    if (order.isTakeaway) {
      return 'Takeaway';
    } else if (order.hotelBookingId) {
      return 'Hotel';
    } else {
      return 'Restaurant';
    }
  };

  const FilterComponent = ({ isMobile = false }) => (
    <SimpleGrid columns={isMobile ? 1 : 4} spacing={4} width="100%">
      <Box>
        <Text mb={2} color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>Start Date</Text>
        <Flex>
          <IconButton
            icon={<FaCalendarAlt />}
            mr={2}
            aria-label="Start Date"
            bg={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
          />
          <Input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            bg={colorMode === 'dark' ? 'gray.700' : 'white'}
            border="none"
          />
        </Flex>
      </Box>
      <Box>
        <Text mb={2} color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>End Date</Text>
        <Flex>
          <IconButton
            icon={<FaCalendarAlt />}
            mr={2}
            aria-label="End Date"
            bg={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
          />
          <Input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            bg={colorMode === 'dark' ? 'gray.700' : 'white'}
            border="none"
          />
        </Flex>
      </Box>
      <Box>
        <Text mb={2} color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>Status</Text>
        <Flex>
          <IconButton
            icon={<FaTags />}
            mr={2}
            aria-label="Status"
            bg={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
          />
          <Select
            value={status}
            onChange={handleStatusChange}
            bg={colorMode === 'dark' ? 'gray.700' : 'white'}
            border="none"
          >
            <option>All Status</option>
            <option>PENDING</option>
            <option>PREPARING</option>
            <option>READY</option>
            <option>DELIVERED</option>
          </Select>
        </Flex>
      </Box>
      <Box>
        <Text mb={2} color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>Type</Text>
        <Flex>
          <IconButton
            icon={<FaBed />}
            mr={2}
            aria-label="Type"
            bg={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
          />
          <Select
            value={roomType}
            onChange={handleRoomTypeChange}
            bg={colorMode === 'dark' ? 'gray.700' : 'white'}
            border="none"
          >
            <option>All</option>
            <option>Main Restaurant</option>
            <option>Upper Restaurant</option>
            <option>Hotel</option>
            <option>Takeaway</option>
          </Select>
        </Flex>
      </Box>
    </SimpleGrid>
  );

  return (
    <Flex minHeight="100vh">
      <Sidebar />
      <Box
        flex="1"
        bg={colorMode === 'dark' ? 'gray.900' : 'gray.50'}
        ml={{ base: 0, md: '250px' }}
        transition="all 0.3s ease-in-out"
        pb={{ base: '60px', md: 0 }}
      >
        <Box p={4}>
          <Flex justifyContent="space-between" alignItems="center" mb={6}>
            <Text
              fontSize="3xl"
              fontWeight="bold"
              color={colorMode === 'dark' ? 'purple.400' : 'purple.600'}
            >
              All Orders
            </Text>
            {displayType === 'drawer' && (
              <IconButton
                icon={<FaFilter />}
                onClick={onOpen}
                aria-label="Open filters"
                colorScheme="purple"
              />
            )}
          </Flex>

          {displayType === 'inline' && (
            <Box mb={6}>
              <FilterComponent />
            </Box>
          )}

          <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Filters</DrawerHeader>
              <DrawerBody>
                <FilterComponent isMobile={true} />
              </DrawerBody>
            </DrawerContent>
          </Drawer>

          <AnimatePresence>
            {isLoading ? (
              <Center h="200px">
                <Spinner 
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='purple.500'
                  size='xl'
                />
              </Center>
            ) : (
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                {filteredOrders.map((order, index) => (
                  <MotionBox
                    key={order.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <Box
                      bg={colorMode === 'dark' ? 'gray.800' : 'white'}
                      borderRadius="lg"
                      overflow="hidden"
                      onClick={() => handleOrderClick(order)}
                      cursor="pointer"
                      boxShadow="md"
                      position="relative"
                      _before={{
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '4px',
                        bgGradient: 'linear(to-r, purple.500, cyan.500)',
                      }}
                    >
                      <Box p={4}>
                        <Flex justify="space-between" align="center" mb={2}>
                          <Text fontWeight="bold">Order #{order.id}</Text>
                          <Text fontSize="sm" color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>{order.formattedTime}</Text>
                        </Flex>
                        <HStack mb={4}>
                          {order.hotelBookingId ? (
                            <Badge bg={colorMode === 'dark' ? 'gray.700' : 'gray.200'}>
                              Room {order.hotelBookingId}
                            </Badge>
                          ) : (
                            !order.isTakeaway && (
                              <Badge bg={colorMode === 'dark' ? 'gray.700' : 'gray.200'}>
                                Table {order.tableNumber}
                              </Badge>
                            )
                          )}
                          {order.area && (
                            <Badge colorScheme="teal">
                              {order.area.split(' ')[0].charAt(0).toUpperCase() + order.area.split(' ')[0].slice(1)}
                            </Badge>
                          )}
                          {!order.isTakeaway && (
                            <Badge colorScheme={order.ac ? 'green' : 'blue'}>
                              {order.ac ? 'AC' : 'NON AC'}
                            </Badge>
                          )}
                          <Badge colorScheme={order.paymentStatus === 'Paid' ? 'pink' : 'orange'}>
                            {order.orderItems[0]?.preparationStatus || 'Pending'}
                          </Badge>
                          <Badge colorScheme="purple">
                            {getOrderType(order)}
                          </Badge>
                        </HStack>
                        <VStack align="stretch" spacing={2}>
                          {order.orderItems.map((item, itemIndex) => (
                            <Flex
                              key={itemIndex}
                              justify="space-between"
                              align="center"
                              bg={colorMode === 'dark' ? 'gray.700' : 'gray.100'}
                              p={2}
                              borderRadius="md"
                            >
                              <HStack>
                                <Text>{item.menuItem.subCategory !== 'Veg' ? '🥬' : '🍗'}</Text>
                                <VStack align="start" spacing={0}>
                                  <Text>{item.menuItem.name}</Text>
                                  <Text fontSize="sm" color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>x {item.quantity}</Text>
                                </VStack>
                              </HStack>
                              <VStack align="end" spacing={0}>
                                <Text fontWeight="bold">₹{item.totalPerItem.toFixed(2)}</Text>
                                <Badge colorScheme={getStatusColor(item.preparationStatus || 'Pending')}>
                                  {item.preparationStatus || 'Pending'}
                                </Badge>
                                <Button
                                  size="sm"
                                  leftIcon={<FaEdit />}
                                  onClick={(e) => handleEditOrder(e, item, order)}
                                  mt={1}
                                  isDisabled={order.paymentStatus === 'Paid'}
                                >
                                  Edit
                                </Button>
                              </VStack>
                            </Flex>
                          ))}
                        </VStack>
                        <Text fontWeight="bold" textAlign="right" mt={4}>
                          Grand Total: ₹{order.totalOrderAmount.toFixed(2)}
                        </Text>
                      </Box>
                    </Box>
                  </MotionBox>
                ))}
              </SimpleGrid>
            )}
          </AnimatePresence>
        </Box>
      </Box>
      {selectedOrder && (
        <BillingModal
          order={selectedOrder}
          //onClose={onClose}
          onClose={() => setShowModal(false)}
          token={token}
        />
      )}
      <Modal
        isOpen={isEditOrderSheetVisible}
        onClose={() => setIsEditOrderSheetVisible(false)}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <AnimatePresence>
          {isEditOrderSheetVisible && selectedOrderItem && (
            <MotionModalContent
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "spring", damping: 25, stiffness: 500 }}
              borderTopRadius="20px"
              bg={colorMode === 'dark' ? 'gray.800' : 'white'}
            >
              <ModalHeader borderBottomWidth="1px">Edit Order</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack spacing={6} align="stretch">
                  <HStack justify="space-between">
                    <Text fontSize="lg" fontWeight="bold">{selectedOrderItem.menuItem?.name || 'Unknown Item'}</Text>
                    <HStack>
                      <IconButton
                        icon={<FaMinus />}
                        onClick={() => {
                          if (selectedOrderItem.quantity > 1) {
                            setSelectedOrderItem({
                              ...selectedOrderItem,
                              quantity: selectedOrderItem.quantity - 1
                            });
                          }
                        }}
                        isDisabled={selectedOrderItem.quantity <= 1}
                        variant="outline"
                      />
                      <Text fontSize="lg" fontWeight="bold">{selectedOrderItem.quantity}</Text>
                      <IconButton
                        icon={<FaPlus />}
                        onClick={() => {
                          setSelectedOrderItem({
                            ...selectedOrderItem,
                            quantity: selectedOrderItem.quantity + 1
                          });
                        }}
                        variant="outline"
                      />
                    </HStack>
                  </HStack>
                  <HStack justify="space-between">
                    <Button
                      onClick={() => handleOrderUpdate(selectedOrderItem)}
                      colorScheme="blue"
                      flex={1}
                    >
                      Save Changes
                    </Button>
                    <IconButton
                      icon={<FaTrash />}
                      onClick={() => handleDeleteOrderItem(selectedOrderItem)}
                      colorScheme="red"
                      aria-label="Delete item"
                    />
                  </HStack>
                </VStack>
              </ModalBody>
            </MotionModalContent>
          )}
        </AnimatePresence>
      </Modal>
    </Flex>
  );
};

export default AllOrders;
