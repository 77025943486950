import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  Button,
  IconButton,
  useColorMode,
  useColorModeValue,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react';
import { FaArrowLeft, FaMinus, FaPlus } from 'react-icons/fa';
import Sidebar from '../components/Sidebar';

const OrderDetailsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [orderItems, setOrderItems] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const employeeId = useSelector((state) => state.auth.employeeId);
  const { colorMode } = useColorMode();
  const toast = useToast();
  const displayType = useBreakpointValue({ base: 'bottom', md: 'sidebar' });
  const [number, setNumber] = useState('');
  const [reservationDate, setReservationDate] = useState('');
  const [reservationStartTime, setReservationStartTime] = useState('');
  const [reservationEndTime, setReservationEndTime] = useState('');
  const { tableId, itemsInCart, roomDetails, selectedTableType } = location.state || {};
  const [existingOrder, setExistingOrder] = useState(null);
  const grandTotalBgColor = useColorModeValue('purple.100', 'purple.900');
  const [isTakeaway, setIsTakeaway] = useState(false);
  const [id, setId] = useState(null)
  const [existingOrderId, setExistingOrderId] = useState(null);


  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const accentColor = useColorModeValue('purple.600', 'purple.300');

  useEffect(() => {
    const currentURL = window.location.href;
    console.log('currentURL', currentURL);

    if (location.state?.isRoomService || tableId?.startsWith('room')) {
      console.log('=== ROOM SERVICE ORDER DETAILS PAGE ===');
      console.log('Location State:', location.state);
      console.log('URL Parameters:', {
        tableId,
        number,
        id
      });
    }

    const numberMatch = currentURL.match(/number(\d+)/);
    if (numberMatch) {
      const numberPart = numberMatch[1];
      setNumber(numberPart);
      console.log('Numeric part next to "number":', numberPart);
    }
    const idMatch = currentURL.match(/id(\d+)/);
    if (idMatch) {
      const idPart = idMatch[1];
      setId(idPart);
      console.log('ID:', idPart);
    }
    const existingOrderIdMatch = currentURL.match(/existingOrderId(\d+)/);

    if (existingOrderIdMatch) {
      const existingOrderId = existingOrderIdMatch[1];
      console.log('Existing Order ID:', existingOrderId);
      //setExistingOrderId(existingOrderId); // Assuming you have a state variable for existingOrderId
    }
  }, []);

  useEffect(() => {
    const currentURL = window.location.href;
    const queryParams = new URLSearchParams(window.location.search);
    const reservationId = queryParams.get('reservationId');
    const orderId = queryParams.get('orderId');
    const orderType = queryParams.get('type');
    const orderTypeId = queryParams.get('id');
    setIsTakeaway(orderType === 'takeaway');

    console.log('Order Details Page Load:', {
      currentURL,
      reservationId,
      orderId,
      orderType,
      orderTypeId,
      locationState: location.state
    });

    // For room service, check existing orders using EC2 endpoint
    if (location.state?.isRoomService && location.state?.bookingId) {
      const fetchRoomServiceOrder = async () => {
        try {
          const response = await axios.get(
            `http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/waiter/order/getForHotel?id=${location.state.bookingId}`,
            {
              headers: { 'Authorization': `Bearer ${token}` }
            }
          );
          console.log('Room Service Order Check:', response.data);
          if (response.data && response.data.length > 0) {
            setExistingOrder(response.data[0]);
          }
        } catch (error) {
          console.error('Error checking room service order:', error);
        }
      };
      fetchRoomServiceOrder();
      return; // Early return to prevent other API calls
    }

    // Only fetch regular table orders if not room service
    if (!location.state?.isRoomService && !tableId?.startsWith('room')) {
      const fetchExistingOrder = async () => {
        try {
          // Extract just the numeric table ID from the path
          const numericTableId = tableId?.split('/')[0];
          if (!numericTableId) {
            console.error('Invalid table ID format:', tableId);
            return;
          }

          const response = await axios.get(
            `https://api.hotelmadhuban.co.in/waiter/order/getByTableAndStaff?tableId=${numericTableId}&staffId=${employeeId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const orderData = response.data;
          if (orderData.id !== null && orderData.reservationId !== null) {
            setExistingOrder(orderData);
          }
        } catch (error) {
          console.error('Error fetching existing order:', error);
        }
      };
      fetchExistingOrder();
    }
  }, [tableId, token, location.state]);

  useEffect(() => {
    const currentDate = new Date().toISOString().slice(0, 10);
    const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    const endTime = new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    setReservationDate(currentDate);
    setReservationStartTime(currentTime);
    setReservationEndTime(endTime);
  }, []);

  useEffect(() => {
    const { itemsInCart, menuItems } = location.state || {};

    if (itemsInCart && menuItems) {
      const initialOrderItems = itemsInCart.map((cartItem) => {
        const menuItem = menuItems.find((mi) => mi.id === cartItem.id);
        return {
          ...menuItem,
          quantity: cartItem.quantity,
          price: cartItem.price || getPrice(menuItem),
          totalPerItem: cartItem.totalPerItem || (cartItem.price * cartItem.quantity)
        };
      });

      setOrderItems(initialOrderItems);
      calculateGrandTotal(initialOrderItems);
    } else {
      console.error('Data not available');
    }
  }, [location.state]);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleQuantityChange = (itemId, operation) => {
    setOrderItems((prevItems) => {
      const itemIndex = prevItems.findIndex((item) => item.id === itemId);
      const updatedItems = [...prevItems];

      if (itemIndex !== -1) {
        if (operation === 'increase') {
          updatedItems[itemIndex].quantity++;
          updatedItems[itemIndex].totalPerItem = parseFloat((updatedItems[itemIndex].price * updatedItems[itemIndex].quantity).toFixed(2));
        } else if (operation === 'decrease') {
          if (updatedItems[itemIndex].quantity === 1) {
            const shouldRemove = window.confirm('Do you want to remove this item from the order?');
            if (shouldRemove) {
              updatedItems.splice(itemIndex, 1);
            }
          } else {
            updatedItems[itemIndex].quantity--;
            updatedItems[itemIndex].totalPerItem = parseFloat((updatedItems[itemIndex].price * updatedItems[itemIndex].quantity).toFixed(2));
          }
        }
      }

      calculateGrandTotal(updatedItems);
      return updatedItems;
    });
  };

  const calculateGrandTotal = (items) => {
    const newGrandTotal = items.reduce((total, item) => {
      const itemTotal = item.totalPerItem || (item.price * item.quantity);
      return parseFloat((total + itemTotal).toFixed(2));
    }, 0);
    setGrandTotal(newGrandTotal);
  };

  const addItemsToOrder = async (orderId, orderItemsData) => {
    try {
      const response = await axios.put(
        `https://api.hotelmadhuban.co.in:443/waiter/order/addItems?id=${orderId}`,
        orderItemsData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error adding items to order:', error);
      throw error;
    }
  };

  const handleConfirmOrder = async () => {
    try {
      // Handle room service orders
      if (location.state?.isRoomService || tableId?.startsWith('room')) {
        console.log('=== ROOM SERVICE ORDER CONFIRMATION ===');

        // Get booking ID either from state or URL
        const bookingId = location.state?.bookingId || tableId?.match(/room(\d+)/)?.[1];
        const roomNumber = location.state?.roomNumber;
        const phoneNumber = location.state?.phoneNumber;

        if (!bookingId || !roomNumber || !phoneNumber) {
          console.error('Missing required room service details:', { bookingId, roomNumber, phoneNumber });
          toast({
            title: "Error",
            description: "Missing required room service details",
            status: "error",
            duration: 3000,
            isClosable: true
          });
          return;
        }

        console.log('Room Service Details:', {
          bookingId,
          roomNumber,
          phoneNumber,
          itemsInCart,
          existingOrder
        });

        let orderId;

        // If we have an existing order, use its ID
        if (existingOrder && existingOrder.id) {
          console.log('Using existing room service order:', existingOrder.id);
          orderId = existingOrder.id;
        } else {
          // Create new room service order if none exists
          const createPayload = {
            hotelBookingId: parseInt(bookingId),
            phone: parseInt(phoneNumber),
            roomNumber: parseInt(roomNumber),
            staffId: employeeId
          };

          console.log('Creating room service order with payload:', createPayload);

          const createResponse = await axios.post(
            'http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/waiter/order/createForHotel',
            createPayload,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            }
          );

          orderId = createResponse.data;
          console.log('Created new room service order:', orderId);
        }

        // Add items to the order using the appropriate orderId
        const itemsPayload = {
          orderItemDto: itemsInCart.map(item => ({
            menuItemId: item.id,
            menuId: 3,
            specialInstructions: '',
            quantity: item.quantity
          }))
        };

        await addItemsToOrder(orderId, itemsPayload);

        toast({
          title: "Success",
          description: "Room service order processed successfully!",
          status: "success",
          duration: 3000,
          isClosable: true
        });

        history.push('/table-list');
        return;
      }

      // Handle takeaway orders
      if (isTakeaway) {
        const takeawayPayload = {
          takeawayOrderDto: {
            specialInstructions: ""
          },
          orderItemsList: {
            orderItemDto: itemsInCart.map(item => ({
              menuId: 1,
              menuItemId: item.id,
              quantity: item.quantity,
              specialInstructions: ""
            }))
          }
        };

        const response = await axios.put(
          'https://api.hotelmadhuban.co.in/take-away/createAndAddItems',
          takeawayPayload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        console.log('Takeaway order created:', response.data);
        toast({
          title: "Success",
          description: "Takeaway order created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.push('/table-list');
        return;
      }

      // Handle regular table orders
      if (existingOrder && existingOrder.id) {
        console.log('Using existing table order:', existingOrder.id);

        // Add items to existing order
        await addItemsToOrder(existingOrder.id, {
          orderItemDto: itemsInCart.map((item) => ({
            menuItemId: item.id,
            menuId: 3,
            specialInstructions: '',
            quantity: item.quantity,
          })),
        });

        console.log('Items added to existing order successfully');
        toast({
          title: "Success",
          description: "Order updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.push(`/table/${tableId}?orderId=${existingOrder.id}`);
      } else {
        // Create new table order
        const reservationData = {
          customerDto: {
            firstName: 'ABCD',
            lastName: 'PQRS',
            phone: '1234',
          },
          tableId: parseInt(tableId),
          reservationDate,
          reservationStartTime,
          reservationEndTime,
          numberOfGuests: 5,
        };

        const reservationResponse = await axios.post(
          'https://api.hotelmadhuban.co.in:443/reservation/create',
          reservationData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        const newReservationId = reservationResponse.data;
        const orderData = {
          orderDate: reservationDate,
          orderTime: reservationStartTime,
          reservationId: newReservationId,
          tableId: parseInt(tableId),
          staffId: employeeId === null || employeeId === "null" ? 18 : employeeId,
          customerDto: {
            id: 1,
            phone: 1234,
          },
        };

        const orderResponse = await axios.post(
          'https://api.hotelmadhuban.co.in:443/waiter/order/create',
          orderData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        const newOrderId = orderResponse.data;
        await addItemsToOrder(newOrderId, {
          orderItemDto: itemsInCart.map((item) => ({
            menuItemId: item.id,
            menuId: 3,
            specialInstructions: '',
            quantity: item.quantity,
          })),
        });
        console.log('Reservation, order, and items added successfully');
        toast({
          title: "Success",
          description: "Order created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.push(`/table/${tableId}?orderId=${newOrderId}`);
      }
    } catch (error) {
      console.error('Error confirming order:', error);
      toast({
        title: "Error",
        description: "An error occurred while confirming the order. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateExistingItems = async (existingOrder, newItems) => {
    for (const existingItem of existingOrder.orderItems) {
      const newItem = newItems.find(item => item.id === existingItem.menuItem.id);
      if (newItem) {
        const updatedQuantity = existingItem.quantity + newItem.quantity;
        await axios.put(
          `https://api.hotelmadhuban.co.in/order-item/edit?id=${existingItem.id}`,
          {
            menuItemId: existingItem.menuItem.id,
            quantity: updatedQuantity,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }
    }
  };

  const getNewItems = (existingOrder, newItems) => {
    return newItems.filter(newItem =>
      !existingOrder.orderItems.some(existingItem => existingItem.menuItem.id === newItem.id)
    ).map(item => ({
      menuItemId: item.id,
      menuId: 3,
      specialInstructions: '',
      quantity: item.quantity,
    }));
  };

  const getPrice = (item) => {
    switch (selectedTableType) {
      case 'ac':
        return item.acPrice;
      case 'upper':
        return item.upperPrice;
      case 'main':
      default:
        return item.mainPrice;
    }
  };

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error: {error}</Text>;
  }

  return (
    <Flex minHeight="100vh">
      <Sidebar />
      <Box
        flex="1"
        p={4}
        bg={bgColor}
        ml={{ base: 0, md: '250px' }}
        transition="all 0.3s ease-in-out"
        pb={{ base: '60px', md: 0 }}
      >
        <VStack spacing={4} align="stretch">
          <Flex align="center" mb={4}>
            <IconButton
              icon={<FaArrowLeft />}
              onClick={handleBackClick}
              aria-label="Back"
              variant="ghost"
              mr={4}
            />
            <Text
              fontSize="2xl"
              fontWeight="bold"
              bgGradient="linear(to-r, #6c5ce7, #00cec9)"
              bgClip="text"
            >
              Order Details
            </Text>
          </Flex>

          <Box bg={cardBgColor} borderRadius="lg" p={4} boxShadow="md">
            <VStack spacing={4} align="stretch">
              {orderItems.map((item) => (
                <Flex key={item.id} justify="space-between" align="center">
                  <Text fontWeight="medium">{item.name}</Text>
                  <HStack>
                    <Text color={accentColor}>₹{item.price.toFixed(2)}</Text>
                    <IconButton
                      size="sm"
                      icon={<FaMinus />}
                      onClick={() => handleQuantityChange(item.id, 'decrease')}
                      isDisabled={item.quantity === 1}
                    />
                    <Text>{item.quantity}</Text>
                    <IconButton
                      size="sm"
                      icon={<FaPlus />}
                      onClick={() => handleQuantityChange(item.id, 'increase')}
                    />
                  </HStack>
                </Flex>
              ))}
            </VStack>
          </Box>

          <Flex justify="space-between" align="center" p={4} bgGradient="linear(to-r, #6c5ce7, #00cec9)" borderRadius="lg">
            <Text fontWeight="bold" style={{ color: 'white' }}>Grand Total:</Text>
            <Text fontWeight="bold" style={{ color: 'white' }}>₹{grandTotal.toFixed(2)}</Text>
          </Flex>

          <Button bg="#6c5ce7"
            color="white"
            _hover={{ bg: "#5b50b1" }} size="lg" onClick={handleConfirmOrder}>
            Confirm Order
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
};

export default OrderDetailsPage;