// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';


// const initialState = {
//   user: null,
//   role: localStorage.getItem('role') || null,
//   token: localStorage.getItem('token') || null,
//   employeeId: localStorage.getItem('employeeId') || null,
//   isLoading: false,
//   isError: false,
//   errorMessage: '',
// };

// export const loginUser = createAsyncThunk(
//   'auth/loginUser',
//   async (credentials, { rejectWithValue }) => {
//     try {
//       // First API call to get token
//       const tokenResponse = await axios.post(
//         'https://api.hotelmadhuban.co.in/user/token',
//         credentials
//       );
//       const [role, token] = tokenResponse.data.split(' ');
     
//       // Second API call to get user details
//       const userDetailsResponse = await axios.get(
//         'https://api.hotelmadhuban.co.in/user/user-details',
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
     
//       const userDetails = userDetailsResponse.data;
     
//       // Store in localStorage
//       localStorage.setItem('token', token);
//       localStorage.setItem('role', role);
//       localStorage.setItem('employeeId', userDetails.employeeId);

//       return { role, token, userDetails };
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const logoutUser = createAsyncThunk(
//   'auth/logoutUser',
//   async (_, { getState, rejectWithValue }) => {
//     try {
//       const token = getState().auth.token;
//       await axios.post(
//         'https://api.hotelmadhuban.co.in/user/logout',
//         {},
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       return;
//     } catch (error) {
//       console.error('Logout error:', error);
//       return rejectWithValue('Logout failed on server, but local logout will proceed.');
//     }
//   }
// );

// const authSlice = createSlice({
//   name: 'auth',
//   initialState,
//   reducers: {
//     clearAuthState: (state) => {
//       state.user = null;
//       state.role = null;
//       state.token = null;
//       state.employeeId = null;
//       state.isLoading = false;
//       state.isError = false;
//       state.errorMessage = '';
//       localStorage.removeItem('token');
//       localStorage.removeItem('role');
//       localStorage.removeItem('employeeId');
//     },
//     setUserDetails: (state, action) => {
//       state.user = action.payload;
//       state.employeeId = action.payload.employeeId;
//       localStorage.setItem('employeeId', action.payload.employeeId);
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(loginUser.pending, (state) => {
//         state.isLoading = true;
//         state.isError = false;
//         state.errorMessage = '';
//       })
//       .addCase(loginUser.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.user = action.payload.userDetails;
//         state.token = action.payload.token;
//         state.role = action.payload.role;
//         state.employeeId = action.payload.userDetails.employeeId;
//       })
//       .addCase(loginUser.rejected, (state, action) => {
//         state.isLoading = false;
//         state.isError = true;
//         state.errorMessage = action.payload || 'Something went wrong';
//       })
//       .addCase(logoutUser.fulfilled, (state) => {
//         state.user = null;
//         state.token = null;
//         state.role = null;
//         state.employeeId = null;
//         localStorage.removeItem('token');
//         localStorage.removeItem('role');
//         localStorage.removeItem('employeeId');
//       })
//       .addCase(logoutUser.rejected, (state, action) => {
//         console.warn('Server logout failed:', action.payload);
//         state.user = null;
//         state.token = null;
//         state.role = null;
//         state.employeeId = null;
//         localStorage.removeItem('token');
//         localStorage.removeItem('role');
//         localStorage.removeItem('employeeId');
//       });
//   },
// });

// export const { clearAuthState, setUserDetails } = authSlice.actions;
// export default authSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  user: null,
  role: localStorage.getItem('role') || null,
  token: localStorage.getItem('token') || null,
  employeeId: localStorage.getItem('employeeId') || null,
  isLoading: false,
  isError: false,
  errorMessage: '',
};

// Helper function to check token expiration and refresh if needed
const getToken = async () => {
  const expirationTime = localStorage.getItem('expirationTime');
  const isTokenExpired = Date.now() > expirationTime;

  if (isTokenExpired) {
    return await refreshToken();
  }
 
  return localStorage.getItem('token');
};

// Helper function to refresh token
const refreshToken = async () => {
  try {
    const refreshTokenResponse = await axios.post(
      'https://api.hotelmadhuban.co.in/user/refresh-token',
      {
        token: localStorage.getItem('token'),
      }
    );

    const [newToken, expiresIn] = refreshTokenResponse.data.split(' ');
    const expirationTime = Date.now() + expiresIn * 1000;

    // Update the token and expiration time in localStorage
    localStorage.setItem('token', newToken);
    localStorage.setItem('expirationTime', expirationTime);

    return newToken;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw error;
  }
};

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (credentials, { rejectWithValue }) => {
    try {
      // First API call to get token
      const tokenResponse = await axios.post(
        'https://api.hotelmadhuban.co.in/user/token',
        credentials
      );
      const [role, token, expiresIn] = tokenResponse.data.split(' '); // Assuming 'expiresIn' is provided by the API in seconds

      const expirationTime = Date.now() + expiresIn * 1000;

      // Store in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      localStorage.setItem('expirationTime', expirationTime);

      // Second API call to get user details
      const userDetailsResponse = await axios.get(
        'https://api.hotelmadhuban.co.in/user/user-details',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const userDetails = userDetailsResponse.data;

      if (userDetails.roles === 'ROLE_ADMIN') {
        userDetails.employeeId = '18';
      }

      console.log('employeeId', userDetails.employeeId);
      localStorage.setItem('employeeId', userDetails.employeeId);

      return { role, token, expirationTime, userDetails };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (_, { getState, rejectWithValue }) => {
    try {
      const token = await getToken(); // Use the token with refresh logic
      await axios.post(
        'https://api.hotelmadhuban.co.in/user/logout',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return;
    } catch (error) {
      console.error('Logout error:', error);
      return rejectWithValue('Logout failed on server, but local logout will proceed.');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthState: (state) => {
      state.user = null;
      state.role = null;
      state.token = null;
      state.employeeId = null;
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = '';
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('employeeId');
      localStorage.removeItem('expirationTime');
    },
    setUserDetails: (state, action) => {
      state.user = action.payload;
      state.employeeId = action.payload.employeeId;
      localStorage.setItem('employeeId', action.payload.employeeId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.userDetails;
        state.token = action.payload.token;
        state.role = action.payload.role;
        state.employeeId = action.payload.userDetails.employeeId;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload || 'Something went wrong';
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.user = null;
        state.token = null;
        state.role = null;
        state.employeeId = null;
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('employeeId');
        localStorage.removeItem('expirationTime');
      })
      .addCase(logoutUser.rejected, (state, action) => {
        console.warn('Server logout failed:', action.payload);
        state.user = null;
        state.token = null;
        state.role = null;
        state.employeeId = null;
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('employeeId');
        localStorage.removeItem('expirationTime');
      });
  },
});

export const { clearAuthState, setUserDetails } = authSlice.actions;
export default authSlice.reducer;