import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Spinner,
  Alert,
  AlertIcon
} from '@chakra-ui/react';
import { apiCall } from './apiUtils';

const DishesCategory = ({ token }) => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ category: '', subCategory: '' });
  const [editingCategory, setEditingCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const data = await apiCall('/category-config/getAll', 'GET', token);
      setCategories(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch categories');
      console.error('Error fetching categories:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = async () => {
    try {
      await apiCall('/category-config/create', 'POST', token, newCategory);
      setNewCategory({ category: '', subCategory: '' });
      fetchCategories();
      toast({
        title: 'Category created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error creating category',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEdit = async () => {
    try {
      await apiCall(`/category-config/edit?id=${editingCategory.id}`, 'PUT', token, editingCategory);
      setEditingCategory(null);
      fetchCategories();
      toast({
        title: 'Category updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating category',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await apiCall(`/category-config/delete?id=${id}`, 'DELETE', token);
      fetchCategories();
      toast({
        title: 'Category deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error deleting category',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (loading) return <Spinner />;
  if (error) return <Alert status="error"><AlertIcon />{error}</Alert>;

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        <Box>
          <FormControl>
            <FormLabel>Category</FormLabel>
            <Input
              value={newCategory.category}
              onChange={(e) => setNewCategory({ ...newCategory, category: e.target.value })}
            />
          </FormControl>
          <FormControl mt={2}>
            <FormLabel>Sub Category</FormLabel>
            <Input
              value={newCategory.subCategory}
              onChange={(e) => setNewCategory({ ...newCategory, subCategory: e.target.value })}
            />
          </FormControl>
          <Button mt={2} onClick={handleCreate}>Create Category</Button>
        </Box>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Category</Th>
              <Th>Sub Category</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {categories.map((category) => (
              <Tr key={category.id}>
                <Td>{editingCategory?.id === category.id ? 
                  <Input 
                    value={editingCategory.category} 
                    onChange={(e) => setEditingCategory({ ...editingCategory, category: e.target.value })}
                  /> : category.category}
                </Td>
                <Td>{editingCategory?.id === category.id ? 
                  <Input 
                    value={editingCategory.subCategory} 
                    onChange={(e) => setEditingCategory({ ...editingCategory, subCategory: e.target.value })}
                  /> : category.subCategory}
                </Td>
                <Td>
                  <HStack>
                    {editingCategory?.id === category.id ? (
                      <>
                        <Button onClick={handleEdit}>Save</Button>
                        <Button onClick={() => setEditingCategory(null)}>Cancel</Button>
                      </>
                    ) : (
                      <>
                        <Button onClick={() => setEditingCategory(category)}>Edit</Button>
                        <Button onClick={() => handleDelete(category.id)}>Delete</Button>
                      </>
                    )}
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>
    </Box>
  );
};

export default DishesCategory;